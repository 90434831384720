<script>
import layoutProfile from "@/views/layouts/layoutProfile";

export default {
	name: "faq",
	components: {layoutProfile}
}
</script>

<template>
	<layoutProfile>
		<div class="w-100"
			 style="overflow-x: hidden;overflow-y: auto;max-height: calc(100vh - 108px);margin-bottom: 40px;">
			<h1 class="px-4 pt-4 mb-0">F.A.Q</h1>
			<div class="px-4 pt-4 mb-0">
				<h5>1. What is Romeo?</h5>
				<p>Romeo is an AI-powered dating assistant that helps you navigate the world of online dating with ease.
					Our advanced AI technology assists you in crafting engaging messages, scheduling dates, and
					optimizing your dating profile to increase your chances of success.</p>
				<h5>2. How does Romeo work?</h5>
				<p>Romeo utilizes sophisticated algorithms and natural language processing to analyze your preferences
					and conversation patterns. It then generates personalized messages tailored to each match, helping
					you make meaningful connections effortlessly.</p>
				<h5>3. Is Romeo compatible with all dating platforms?</h5>
				<p>Yes, Romeo is designed to work seamlessly with popular dating platforms, including Tinder, Bumble,
					OkCupid, and more. Whether you're using a website or mobile app, Romeo can enhance your dating
					experience across platforms.</p>
				<h5>4. Can Romeo guarantee success in dating?</h5>
				<p>While Romeo significantly improves your chances of success in online dating, we cannot guarantee
					specific outcomes. Success ultimately depends on various factors, including your compatibility with
					potential matches and the quality of your interactions.</p>
				<h5>5. Is my data secure with Romeo?</h5>
				<p>Absolutely. We take privacy and security seriously. Your data is encrypted and stored securely, and
					we adhere to strict privacy policies to ensure your information remains confidential.</p>
				<h5>6. Can I customize Romeo's responses?</h5>
				<p>Yes, you have full control over the messages Romeo sends on your behalf. You can customize message
					templates, adjust conversation settings, and provide feedback to tailor Romeo's responses to your
					preferences.</p>
				<h5>7. How does Romeo handle scheduling dates?</h5>
				<p>Romeo offers a convenient scheduling feature that allows you to coordinate dates with matches
					seamlessly. Simply provide your availability, and Romeo will handle the rest, ensuring a smooth and
					hassle-free experience.</p>
				<h5>8. Can I try Romeo for free?</h5>
				<p>Yes, we offer a free trial period so you can experience the benefits of Romeo firsthand. Sign up
					today to start your free trial and revolutionize your dating life with Romeo.</p>

				<h5>9. Is Romeo available on mobile devices?</h5>
				<p>Yes, Romeo is accessible on both desktop and mobile devices, allowing you to manage your dating
					profile and conversations anytime, anywhere.</p>

				<h5>10. How can I contact Romeo's customer support?</h5>
				<p>If you have any questions or need assistance, our friendly customer support team is here to help. You
					can reach us via email at <a href="mailto:support@romeo.com">support@romeo.com</a> or through the contact form on our website.</p>
			</div>
		</div>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>